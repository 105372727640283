<template>
<div id="email-app" >

          <vs-sidebar class="items-no-padding full-vs-sidebar" parent="#email-app"  :hidden-background="true" position-right v-model="sidebar">
             <vira-details @closeSidebar="sidebar = !sidebar" :viraData="consume"></vira-details>
          </vs-sidebar>
          <vx-card>

              <b-table
                class="text-left md:text-center align-middle table table-dark text-sm md:text-base"
                striped
                :stacked="windowWidth < 800"
                :fields="monthlyFields"
                :items="monthlyManual"
                responsive="md"
              >
                <template #cell(jalali_year)="data">
                  {{ data.item.jalali_month }} / {{data.item.jalali_year}}
                </template>
                <template #cell(pay)="pay">
                  <vs-dropdown v-if="pay.item.invoice_type == 1 && pay.item.pay_status == 0" vs-custom-content vs-trigger-click>
                    <vs-button class="rounded-lg" size="small" type="filled" icon="expand_more">پرداخت</vs-button>
                     <vs-dropdown-menu>
                       <vs-dropdown-item>
                         <b-button @click="payMonthlyManual(pay.item.id)"  variant="success" class="w-full rounded-lg" size="sm">پرداخت آنلاین</b-button>
                       </vs-dropdown-item>
                       <vs-dropdown-item>
                         <b-button  variant="outline-success" class="w-full rounded-lg" size="sm" @click="generateMonthlyPayLink(pay.item.id)"> دریافت لینک پرداخت </b-button>
                       </vs-dropdown-item>
                     </vs-dropdown-menu>
                  </vs-dropdown>

                  <!-- <b-button @click="payMonthlyManual(pay.item.id)" v-if="pay.item.invoice_type == 1 && pay.item.pay_status == 0" variant="primary" class="rounded-lg" size="sm">پرداخت آنلاین</b-button> -->
                </template>
                <template #cell(desc)="description" class="flex justify-items-center gap-2 ">

                   <router-link :to="`/apps/manual/ManualPrint/${description.item.id}`" class="text-center">
                   <vs-chip  :color="description.item.pay_status === 1 ? 'success' : 'danger'" class="text-center rounded mt-2 md:mt-0" size="sm">  مشاهده صورتحساب  </vs-chip>
                 </router-link>
               </template>
              </b-table>
              <vs-pagination
                class="center  w-full"
                :total="totalPage"
                v-model="pageNumber"
              ></vs-pagination>
          </vx-card>

    <div>
      <vs-popup title="مصرف ماهانه" :active.sync="manualPopUp">
        <div>
            <vs-alert active  v-if="desc.request_document_description === null"> درخواست شما از در حال پیگیری می باشد.  </vs-alert>
            <span v-else>{{ desc.request_document_description }}</span>
        </div>
      </vs-popup>
    </div>
    <div>
          <vs-popup title="مصرف ماهانه" :active.sync="descriptionPopup">
            <vs-list  class="px-32">
              <vs-list-item
                v-for="(item, index) in description"
                :key="index"
                :title="Object.keys(item)[0]"
              >
                <span class="text-primary">{{ Object.values(item)[0] | money }}</span> تومان
                </vs-list-item
              >
            </vs-list>
          </vs-popup>
          <vs-popup fullscreen title="ریز مصرف" :active.sync="detailedTrans"  >
            <template lang="html">
  <div>
    <vs-table max-items="3" :data="consume">
        <template slot="thead">
        <vs-th>
          مبلغ
        </vs-th>
        <vs-th>
          توضیحات
        </vs-th>
        <vs-th>
          تاریخ
        </vs-th>

      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in meta" >

          <vs-td :data="consume[indextr].amount">
            {{consume[indextr].amount | money}} تومان
          </vs-td>

          <vs-td :data="consume[indextr].meta">
            {{consume[indextr].meta}}
          </vs-td>

          <vs-td :data="consume[indextr].created_at">
            {{consume[indextr].created_at}}
          </vs-td>

        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination  :total="detailedTransCountPage" v-model="detailedTransPageNumber" ></vs-pagination>

  </div>
</template>
          </vs-popup>
    </div>


</div>

</template>

<script>
import ViraDetails from './ViraDetails.vue'
export default {
  name: 'InvoiceManual',
  components : {
    ViraDetails
  },
  data () {
    return {
      detailedTrans: false,
      detailedTransCountPage:0,
      detailedTransPageNumber:1,
      totalPage :1,
      pageNumber:1,
      desc : '',
      consume : [],
      description : '',
      manualPopUp : false,
      descriptionPopup : false,
      sidebar : false,
      monthlyManual: [],
      yearMonth : '',
      manuals : [],
      didbanId : '',
      fields: [

        { key: 'index', label: 'ردیف' },
        { key: 'description', label: 'توضیح' },
        { key: 'from_jalali_date', label: 'از تاریخ' },
        { key: 'to_jalali_date', label: 'تا تاریخ' },
        { key: 'from_jalali_date', label: 'از تاریخ' },
        { key: 'final_amount', label: 'مبلغ پرداخت' },
        { key: 'tax', label: 'مالیات' },
        { key: 'tax_percent', label: 'مالیات (درصد)' },
        { key: 'status_text', label: 'وضعیت فاکتور' },
        { key: 'pay', label: 'عملیات' }

      ],
      monthlyFields: [
        { key: 'jalali_year', label: 'تاریخ' },
        { key: 'amount', label: 'مبلغ' },
        { key: 'tax', label: 'مالیات' },
        { key: 'final_amount', label: 'مبلغ کل' },
        { key: 'pay_status_text', label: 'وضعیت پرداخت' },
        { key: 'desc', label: 'عملیات' }


      ],
      meta:[]
    }
  },
  watch : {
    pageNumber () {
      this.getMonthlyManual()
    },
    detailedTransPageNumber () {
      this.getDetailedTrans()
    }
  },

  computed : {
    screen () {
      return this.$store.state.windowWidth > 400
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  methods : {
    generatePayLink (id) {
      this.$http.get(`/invoice/manual/online/pay/link'?id=${id}`).then((result) => {
        this.$copyText(result.data.params.data).then(() => {
          this.$vs.notify({
            color:'black',
            title: 'لینک  پرداخت کپی شد '
          })
        })
      }).catch()
    },
    generateMonthlyPayLink (id) {
      this.$http.get(`/invoice/monthly/online/pay/link?id=${id}`).then((result) => {
        this.$copyText(result.data.params.data).then(() => {
          this.$vs.notify({
            color:'black',
            title: 'لینک  پرداخت کپی شد '
          })
        })
      }).catch()
    },
    manualStatusPopUp (des) {
      this.manualPopUp = true
      this.desc = des
    },
    openSidebar (month, years) {
      this.sidebar = true
      this.getMonthlyConsume(month, years)
    },
    detailsPopUp (data) {
      this.descriptionPopup = true
      this.description = JSON.parse(data.description)
    },
    getManualList () {
      this.$vs.loading()
      this.$http.get('/invoice/manual/list').then((result) => {
        this.$vs.loading.close()
        this.manuals = result.data.params.data

      }).catch(() => {
        this.$vs.loading.close()
      })
    },
    payManual (id) {
      this.$vs.loading()
      this.$http.post('/invoice/manual/pay', {id}).then((res) => {
        this.$vs.loading.close()
        const data = res.data.params.payment_url
        window.location = (data)
      }).catch((err) => {
        this.$vs.loading.close()
        console.log(err)
      })
    },
    payMonthlyManual (id) {
      this.$vs.loading()
      this.$http.post('/invoice/monthly/pay', {id}).then((res) => {
        this.$vs.loading.close()
        const url = res.data.params.payment_url
        window.location = (url)
      }).catch((err) => {
        this.$vs.loading.close()
        console.log(err)
      })
    },
    getMonthlyManual (year = '', day = '') {
      this.$vs.loading()
      this.$http
        .get(`/billing/monthly/invoice?year=${year}&month=${day}&page=${this.pageNumber}`)
        .then((result) => {
          this.$vs.loading.close()
          this.monthlyManual = result.data.params.data
          this.totalPage = result.data.params.last_page

        })
        .catch(() => {})
    },
    getDetailedTrans () {
      this.$vs.loading()
      this.$http
        .get(`/billing/pay/transaction?type=withdraw&search=&page=${this.detailedTransPageNumber}`)
        .then(res => {
          this.$vs.loading.close()
          this.consume = res.data.params.data
          this.detailedTransCountPage = res.data.params.last_page

          this.meta = this.consume


          this.consume.forEach((item) => {
            if (item.meta.includes('public IP')) {
              item.meta = item.meta.replace(/cloudvm#VM public IP cost for (.*) - 1 Hour\(s\)/, 'بابت آیپی $1')
            } else if (item.meta.includes('1:1  Traffic')) {
              console.log(item.meta)
              item.meta = item.meta.replace(/cloudvm#Vm (.*) - 1:1 .Traffic - (.*) - \([0-9].+\/[0-9].+\) Hour: [0-9]+./, '$2 ترافیک 1 به 1 برای سرور شماره $1')
            } else if (item.meta.includes('HDD only')) {
              item.meta = item.meta.replace(/cloudvm#Vm cost for (.*) - HDD only - (.*) - 1 Hour\(s\)/, 'برای سرور شماره $1 - رزور $2 هارد (خاموش)')
            } else if (item.meta.includes('Vm cost for')) {
              item.meta = item.meta.replace(/cloudvm#Vm cost for (.*) - 1 Hour\(s\)/, 'برای سرور شماره $1 (روشن)')
            }
          })
          //cloudvm#Vm 91044344 - 1:1 Traffic - 0.01 GB - (1401/12/8) Hour: 14

        }).catch(err => {
          console.log(err)
        })
    },
    requestManuals (id) {
      this.$vs.loading()
      this.$http.post('/billing/invoice/request/document', {invoice_id: id}).then((result) => {
        this.$vs.loading.close()
        this.$vs.notify({text: result.data.message})
      }).catch((err) => {
        console.log(err.response)
        this.$vs.loading.close()
        this.$vs.notify({text: err.response.data.message})

      })
    }
  },
  created () {
    this.getManualList()
    this.getMonthlyManual()
    // this.requestManuals()
  }
}
</script>

<style lang="scss" >
.list-group-item {
    color: black;
}
.full-vs-sidebar {
  .vs-sidebar {
    max-width: calc(100% );
    margin-left: 26px;
  }
}

@media only screen and (max-width: 800px){
  .full-vs-sidebar {
    .vs-sidebar {
      max-width: 100%;
      margin-left: 0;
    }
  }
}


</style>
