<template>
  <div class="margin-fix">
      <vx-card class="flex-col">
        <feather-icon
            icon="ArrowRightIcon"
            @click="$emit('closeSidebar')"
            class="cursor-pointer mr-4 bg-primary text-white px-2 py-2 rounded-pill"
            svg-classes="w-6 h-6"
            ></feather-icon>
         <!-- <h4> {{viraData.length === 0}} </h4> -->
      </vx-card>
      <vx-card class="mt-5"> 
         <div v-if="viraData.length === 0">
            <b-alert show> شما در این ماه  مصرفی در سرویس ویرا ابر نداشته اید.</b-alert>
        </div>
        <vue-perfect-scrollbar v-else  class="scroll-area" :settings="settings" >
             <b-list-group>
                <b-list-group-item v-for="(item, index) in viraData" :key="index">{{index + 1}} - {{item.description}}</b-list-group-item>
            </b-list-group>
        </vue-perfect-scrollbar>
        <!-- {{viraData[0]}} -->
      </vx-card>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  props : ['viraData'],
  components : {
    VuePerfectScrollbar
  },
  data () {
    return {
      settings: {
        maxScrollbarLength: 60
      }, 
      fields : [{key: 'description', label: 'توضیحات'}]
    }
  },
  computed : {
    scrollbarTag () { return this.$store.state.is_touch_device ? 'VuePerfectScrollbar' : 'VuePerfectScrollbar' }
  }
}
</script>

<style lang="scss">
.margin-fix {
    margin-top: 70px;
    margin-left: 20px;
}

.scroll-area {
//   position: relative;
//   margin: auto;
  width: 100%;
  height: 300px;
}

.vira-content-scroll-area {
        // position: relative;
        margin: auto;
        width: 100%;
        height: calc(100% - 100px);

        &:not(.ps) {
          overflow-y: auto;
        }
    }
</style>